import React, { useCallback, useRef } from "react";
import { ReactFlow, useNodesState, useEdgesState, addEdge } from "reactflow";
import "reactflow/dist/style.css";
import PraanLogo from "../../../images/praan-logo.png";
import LoginScreen from "../../../images/LoginScreen.png";
import CompanyListing from "../../../images/CompanyListing.png";
import ZonesListing from "../../../images/ZonesListing.png";
import RoomsDetails from "../../../images/RoomDetails.png";
import DeviceDetails from "../../../images/DevicesDetails.png";
import SchedulingDevices from "../../../images/SchedulingDevices.png";
import Client1 from "../../../images/Clients-1.png";
import Client3 from "../../../images/Clients-3.png";
import MK2DeviceDetails from "../../../images/mk2-device.png";
import MK2Service from "../../../images/mk2-service.png";
import MK2Billing from "../../../images/mk2-billing.png";
import MK2Cluster from "../../../images/mk2-cluster.png";

// import ExplodedView from "../../../images/mk2-exploded-view.png";
// import SystemIcon from "../../../images/icons8-system-task-80 1.png";
// import GraphIcon from "../../../images/icons8-graph-80 1.png";
// import WindIcon from "../../../images/icons8-wind-100 1.png";
// import FilterIcon from "../../../images/FilterIcon.png";
import Footer from "../../Home/Components/Footer/contact";
import "./mk2.scss";

// const initialNodes = [
//   {
//     id: "admin",
//     sourcePosition: "right",
//     type: "input",
//     data: { label: "Admin" },
//     position: { x: 0, y: 160 },
//   },
//   {
//     id: "admin-login",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Admin Login" },
//     position: { x: 200, y: 160 },
//   },
//   {
//     id: "company-name",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Company Name" },
//     position: { x: 400, y: 160 },
//   },
//   {
//     id: "insights",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Insights" },
//     position: { x: 600, y: 0 },
//   },
//   {
//     id: "zones",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Zones" },
//     position: { x: 600, y: 80 },
//   },
//   {
//     id: "settings",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Settings" },
//     position: { x: 600, y: 160 },
//   },
//   {
//     id: "maintenance",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Maintenance" },
//     position: { x: 600, y: 240 },
//   },
//   {
//     id: "graphs",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Graphs" },
//     position: { x: 800, y: 80 },
//   },
//   {
//     id: "device",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Device" },
//     position: { x: 800, y: -80 },
//   },
//   {
//     id: "device-details",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Device Details" },
//     position: { x: 1000, y: -80 },
//   },
//   {
//     id: "temperature",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Temperature" },
//     position: { x: 1000, y: 0 },
//   },
//   {
//     id: "aqi",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "AQI" },
//     position: { x: 1000, y: 80 },
//   },
//   {
//     id: "humidity",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Humidity" },
//     position: { x: 1000, y: 160 },
//   },
//   {
//     id: "ambient-sound",
//     sourcePosition: "right",
//     targetPosition: "left",
//     data: { label: "Ambient Sound" },
//     position: { x: 1000, y: 240 },
//   },
// ];

// const initialEdges = [
//   {
//     id: "horizontal-e1-2",
//     source: "admin",
//     type: "smoothstep",
//     target: "admin-login",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-3",
//     source: "admin-login",
//     type: "smoothstep",
//     target: "company-name",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-4",
//     source: "company-name",
//     type: "smoothstep",
//     target: "insights",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-5",
//     source: "company-name",
//     type: "smoothstep",
//     target: "zones",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-6",
//     source: "company-name",
//     type: "smoothstep",
//     target: "settings",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-7",
//     source: "company-name",
//     type: "smoothstep",
//     target: "maintenance",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-8",
//     source: "zones",
//     type: "smoothstep",
//     target: "graphs",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-10",
//     source: "zones",
//     type: "smoothstep",
//     target: "device",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-11",
//     source: "device",
//     type: "smoothstep",
//     target: "device-details",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-12",
//     source: "graphs",
//     type: "smoothstep",
//     target: "temperature",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-13",
//     source: "graphs",
//     type: "smoothstep",
//     target: "aqi",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-14",
//     source: "graphs",
//     type: "smoothstep",
//     target: "humidity",
//     animated: true,
//   },
//   {
//     id: "horizontal-e1-15",
//     source: "graphs",
//     type: "smoothstep",
//     target: "ambient-sound",
//     animated: true,
//   },
// ];

const MK2 = () => {
  // const [nodes, _, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // const onConnect = useCallback(
  //   (params) => setEdges((els) => addEdge(params, els)),
  //   []
  // );

  return (
    <>
      <section className="mk2-section">
        <div className="mk2">
          <div className="mk2-grid">
            <div className="mk2-title">
              MK2 <br />
              Application
            </div>
            <div className="mk2-heading">Fulltime Project</div>
            <div className="praan">
              <img src={PraanLogo} className="praan-logo" alt="" />
            </div>
            <div className="mk2-inline">
              <div className="mk2-description">
                The MK2 application is built to monitor the air in industrial
                sites/zones where the device would be installed.
              </div>
            </div>
            {/* <a href="#verticle-next">
              <div className="arrow">
                <img className="vector" src={OuterArrowIcon} alt="" srcset="" />
                <img className="ellipse" src={ArrowIcon} alt="" srcset="" />
              </div>
            </a> */}
          </div>
        </div>
        <div className="mk2-about">
          <div className="mk2-about-grid">
            <div className="mk2-title">About project</div>
            <div className="mk2-inline">
              <div className="mk2-about-description">
                MK2 application is designed for users who buy the product and
                require a way to control the device and monitor the zones
                surrounding the device. The application allows the user to
                monitor the AQI levels, CO2, NO2, SO2, O2, Temperature,
                Humidity, Particulate matter levels and Ambient sound levels
              </div>
            </div>
            {/* <a href="#verticle-next">
              <div className="arrow">
                <img className="vector" src={OuterArrowIcon} alt="" srcset="" />
                <img className="ellipse" src={ArrowIcon} alt="" srcset="" />
              </div>
            </a> */}
          </div>
        </div>
        <div className="mk2-application-node">
          <div className="mk2-application-heading-grid">
            <div className="mk2-application-header">User Flow</div>
            <div className="mk2-application-subheader">
              The following is the basic user flow of the web application which
              includes the Admin as well as the Client side.
            </div>
          </div>
          {/* <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            zoomOnScroll={false}
            onSelectionDrag={false}
            panOnDrag={false}
            fitView
            attributionPosition="free"
          /> */}
        </div>
        <div className="mk2-wireframes">
          <div className="mk2-wireframes-heading-grid">
            <div className="mk2-wireframes-header">Wireframes</div>
            <div className="mk2-wireframes-subheader">
              The following screens include basic wireframes of the website
              application
            </div>
          </div>
          <div className="wireframe-image-grid">
            <div className="image-grid">
              <div className="image-description-header">Login Screen</div>
              <img src={LoginScreen} />
            </div>
            <div className="image-grid">
              <div className="image-description-header">Company listing</div>
              <img src={CompanyListing} />
            </div>
            <div className="image-grid">
              <div className="image-description-header">Zones listing</div>
              <img src={ZonesListing} />
            </div>
            <div className="image-grid">
              <div className="image-description-header">Room details</div>
              <img src={RoomsDetails} />
            </div>
            <div className="image-grid">
              <div className="image-description-header">Devices details</div>
              <img src={DeviceDetails} />
            </div>
            <div className="image-grid">
              <div className="image-description-header">
                Scheduling of Devices
              </div>
              <img src={SchedulingDevices} />
            </div>
          </div>
        </div>
        <div className="font-color-styles">
          <div className="mk2-fonts-heading-grid">
            <div className="mk2-wireframes-header">Fonts & colors</div>
            <div className="mk2-wireframes-subheader">
              The following screens includes the font style and colours used for
              the MK2 web application
            </div>
          </div>
          <div className="font-panel-row">
            <div className="font-panel">
              <div className="highlight-font"> Sans serif</div>
              <div className="header-font">Articulate CF</div>
              <div className="font-tag-list">
                <div className="tag">bold</div>
                <div className="tag">black</div>
              </div>
              <div className="font-preview">Aa</div>
            </div>
            <div className="font-panel">
              <div className="highlight-font"> Sans serif</div>
              <div className="header-font">Neue Haas Grotesk Display Pro</div>
              <div className="font-tag-list">
                <div className="tag">bold</div>
                <div className="tag">black</div>
              </div>
              <div className="font-preview">Aa</div>
            </div>
          </div>
          <div className="colour-panel-row">
            <div className="colour-column">
              <div className="highlight-font">Main</div>
              <div className="colour-box-f8"></div>
              <div className="colour-code-below-box">#F8F8FC</div>
            </div>
            <div className="colour-column-2">
              <div className="column-1">
                <div className="highlight-font">Accent</div>
                <div className="colour-box-e3"></div>
                <div className="colour-code-below-box">#E3E3E3</div>
              </div>
              <div className="column-2">
                <div className="colour-box-ff"></div>
                <div className="colour-code-below-box">#FFFFFF</div>
              </div>
            </div>
            <div className="colour-column">
              <div className="highlight-font">Other</div>
              <div className="colour-box-29"></div>
              <div className="colour-code-below-box">#292929</div>
            </div>
          </div>
        </div>
        <div className="clients-2-column">
          <div className="clients-column-1">
            <div className="clients-header">Clients & Zones Listing</div>
            <div className="clients-subheader">
              The following screens displays the Client listings and zones
              listing with the current AQI and devices mentioned
            </div>
            <img src={Client1} className="client-zone-image-1" />
            <img src={Client3} className="client-zone-image-2" />
          </div>
        </div>
        <div className="device-service-2-column">
          <div className="device-service-column">
            <div className="clients-header">
              Device <br /> Details
            </div>
            <div className="device-service-subheader">
              The following screen includes details and updates about the
              device, as well as a list of the devices in the specified zone.
            </div>
            <img src={MK2DeviceDetails} className="device-service-image" />
          </div>
          <div className="device-service-column" style={{ paddingLeft: 0 }}>
            <div className="clients-header">
              Service
              <br />
              Mode
            </div>
            <div className="device-service-subheader">
              The following screen includes the service mode screen which is
              accessible to the admin. It allows the admin to test the device
              during maintenance.
            </div>
            <img src={MK2Service} className="device-service-image" />
          </div>
        </div>
        <div className="cluster-billing-2-column">
          <div className="device-service-column">
            <div className="clients-header" style={{ width: 550 }}>
              Cluster & <br /> Device Insights
            </div>
            <div className="clients-subheader">
              The following screen includes daily insights about a single
              device, as well as a cluster of devices
            </div>
            <img src={MK2Cluster} className="device-service-image" />
          </div>
          <div className="device-service-column" style={{ paddingLeft: 0 }}>
            <div className="clients-header">
              Billing &
              <br />
              Maintenance
            </div>
            <div className="clients-subheader" style={{ width: 750 }}>
              The following screen includes billing and maintenance details for
              the user and the admin to receive updates about payments and
              maintenance.
            </div>
            <img src={MK2Billing} className="device-service-image" />
          </div>
        </div>
        <div className="mk2-zone-details">
          <div className="zone-details">
            <div className="clients-header">
              Zone <br /> Details
            </div>
            <div className="clients-subheader" style={{ width: 300 }}>
              The following screen includes details and updates about the
              device, as well as a list of the devices in the specified zone.
            </div>
          </div>
        </div>
        <div className="mk2-live-updates">
          <div className="mk2-live-details">
            <div className="clients-header">Live Updates</div>
            <div className="clients-subheader" style={{ width: 400 }}>
              The application gives you live updates about the air quality
              around the factory
            </div>
          </div>
        </div>
      </section>
      <Footer color={"#d9d9d9"} />
    </>
  );
};
export default MK2;

import React, { useEffect, useRef } from "react";
import Footer from "../../Home/Components/Footer/contact";

import "./atmos.scss";
const ATMOS = () => {
  return (
    <>
      <section className="hive-section"></section>
      <Footer color={"#d9d9d9"} />
    </>
  );
};
export default ATMOS;

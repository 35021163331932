import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home/Home";
import HIVE from "./pages/Projects/HIVE/hive";
import MK2 from "./pages/Projects/MK2/mk2";
import ATMOS from "./pages/Projects/ATMOS/atmos";
import Contact from "./pages/Home/Components/Contact/contact";
import NoPage from "./pages/NoPage";
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/600.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/hive" element={<HIVE />} />
          <Route path="/mk2" element={<MK2 />} />
          <Route path="/atmos" element={<ATMOS />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

import Animate from "./Components/Title/animate";
import About from "./Components/About/about";
import Gallery from "./Components/Gallery/gallery";
import Contact from "./Components/Contact/contact";
import "./styles.scss";
import React, { useEffect, useRef } from "react";
import Lenis from "lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Components/Projects/projects.scss";
import Pacseal from "../../images/Pacseal.png";
import FlashjetPump from "../../images/FlashjetPump.png";
import Hive from "../../images/HiveHomepage.png";
import HiveApplication from "../../images/HiveApplication.png";
// import "./App.css"; // Assume styles are defined in App.css

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const boxItemsRef = useRef([]);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    function raf(time) {
      lenis.raf(time);
      ScrollTrigger.update();
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // Horizontal scrolling setup
    const boxItems = gsap.utils.toArray(boxItemsRef.current);

    ScrollTrigger.create({
      trigger: section2Ref.current,
      start: "top top",
      end: () => `+=${section2Ref.current.scrollWidth - window.innerWidth}`,
      pin: true,
      scrub: 1,
      markers: false,
      // markers: true,
      invalidateOnRefresh: true,
      onUpdate: (self) => {
        const progress = self.progress;
        gsap.to(boxItems, {
          xPercent: -100 * (boxItems.length - 1) * progress,
          ease: "none",
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div>
      <section
        id="vertical"
        ref={section1Ref}
        style={{
          marginBottom: 200,
        }}
      >
        <div>
          <Animate />
          <About />
        </div>
      </section>
      <section
        id="horizontal"
        ref={section2Ref}
        style={{ display: "flex", overflow: "hidden" }}
        className="projects-section"
      >
        <h1 className="projects">Projects</h1>

        <div className="horizontal__content">
          <div
            className="horizontal__item"
            ref={(el) => (boxItemsRef.current[0] = el)}
          >
            <div className="component">
              <div className="overlap-group">
                <div className="rectangle">
                  <p className="senior-year-design">
                    Senior Year Design
                    <br /> Studio Project
                  </p>
                  <img
                    className="float-left-image"
                    alt="Front view"
                    src={Pacseal}
                  />
                  <p className="text-below-image">PACSEAL</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="horizontal__item"
            ref={(el) => (boxItemsRef.current[1] = el)}
          >
            <div className="component">
              <div className="overlap-group">
                <div className="rectangle">
                  <img
                    className="float-right-image"
                    alt="Front view"
                    src={FlashjetPump}
                  />
                  <p className="graduation-project">
                    Graduation <br />
                    Project
                  </p>
                  <p className="text-below-graduation">FLASHJET PUMP</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="horizontal__item"
            ref={(el) => (boxItemsRef.current[2] = el)}
          >
            <div className="component">
              <div className="overlap-group">
                <div className="rectangle">
                  <img
                    className="float-center-image"
                    alt="Front view"
                    src={Hive}
                  />
                  <p className="graduation-project">
                    Fulltime <br />
                    Project
                  </p>
                  <p className="text-left-hive">HIVE</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="horizontal__item"
            ref={(el) => (boxItemsRef.current[3] = el)}
          >
            <div className="component">
              <div className="overlap-group">
                <div className="rectangle">
                  <img
                    className="float-monitor-image"
                    alt="Front view"
                    src={HiveApplication}
                  />
                  <p className="graduation-project">
                    Fulltime <br />
                    Project
                  </p>
                  <p className="text-left-web">HIVE APPLICATION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="vertical"
        ref={section1Ref}
        style={{
          marginBottom: 200,
        }}
      >
        <Gallery />
      </section>
      <section
        id="vertical"
        ref={section1Ref}
        style={{
          marginBottom: 200,
        }}
      >
        <Contact />
      </section>
    </div>
  );
};

export default App;

import React, { useEffect, useRef } from "react";
import "./footer.scss";
import Linkedin from "./LinkedIn.png";
import Instgram from "./Instagram.png";

const Footer = ({ color }) => {
  return (
    <section className="footer-section" style={{ backgroundColor: color }}>
      <h1 className="footer-heading">Contact</h1>
      <h4 className="footer-text">GOT AN IDEA? LET'S MAKE IT REAL</h4>
      <h2 className="footer-email">
        <a href="mailto:design.delaina@gmail.com" className="reset-link">
          Design.Delaina@gmail.com
        </a>
      </h2>
      <div className="footer-image-grid">
        <img src={Linkedin} alt="" className="linkedin-icon" />
        <img src={Instgram} alt="" className="instagram-icon" />
      </div>
      <div>
        <h4 className="copyright-section">
          DESIGNED AND DEVELOPED BY DELAINA & KEEGAN | DESIGN{" "}
          {new Date().getFullYear()}
        </h4>
      </div>
    </section>
  );
};
export default Footer;

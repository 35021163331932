import React, { useEffect, useRef } from "react";
import anime from "animejs";
import Letterize from "https://cdn.skypack.dev/letterizejs@2.0.0";
import "./Animate.css";

const Animate = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const text = textRef.current;
    const letterize = new Letterize({
      targets: ".animate-me div",
    });

    const animation = anime.timeline({
      targets: letterize.listAll,
      delay: anime.stagger(100, {
        grid: [letterize.list[0].length, letterize.list.length],
        from: "center",
      }),
      loop: true,
    });

    animation
      .add({
        scale: 0.5,
      })
      .add({
        letterSpacing: "10px",
      })
      .add({
        scale: 1,
      })
      .add({
        letterSpacing: "6px",
      });
  }, []);

  return (
    <section className="animate-section">
      <div className="title-container">
        <div ref={textRef} className="animate-me">
          <div>Delaina Fernandes</div>
          <div>Industrial Designer</div>
          <div>Visual Designer</div>
          <div>3D Visualizer</div>
        </div>
      </div>
    </section>
  );
};

export default Animate;

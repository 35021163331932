import { Outlet, Link } from "react-router-dom";
import { useState } from "react";
import Hamburger from "../images/Vector 79.png";
import "./layout.scss";

const Layout = () => {
  const [isNavActive, setIsNavActive] = useState(false);

  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };

  return (
    <>
      <img src={Hamburger} className="hamburger-icon" onClick={toggleNav} />
      <nav
        className={`custom-overlay-menu ${isNavActive ? "active" : "inactive"}`}
      >
        <ul>
          <li>
            <Link to="/" onClick={() => setIsNavActive(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/hive" onClick={() => setIsNavActive(false)}>
              HIVE
            </Link>
          </li>
          <li>
            <Link to="/mk2" onClick={() => setIsNavActive(false)}>
              MK2 Application
            </Link>
          </li>
          <li>
            <Link to="/atmos" onClick={() => setIsNavActive(false)}>
              ATMOS
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setIsNavActive(false)}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  );
};

export default Layout;

import React, { useEffect, useRef } from "react";
import "./gallery.scss";
const Gallery = () => {
  return (
    <section className="gallery-section">
      <div class="grid">
        <div>
          <h1 class="gallery">Gallery</h1>
          <div class="img-grid">
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=50" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=10" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=70" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=200" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=90" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=450" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=239" alt="" />
              </a>
            </div>
            <div class="grid-item">
              <a href="https://www.instagram.com/CodePen/">
                <img src="http://unsplash.it/600?image=510" alt="" />
              </a>
            </div>
          </div>
          <div className="gallery-overlay">
            <h1 className="gallery-link">
              VISIT THE GALLERY
              <svg
                style={{ marginLeft: 15 }}
                width="27"
                height="22"
                viewBox="0 0 27 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 11H25.5M25.5 11L13 1M25.5 11L13 21"
                  stroke="black"
                />
              </svg>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Gallery;

import React, { useEffect, useRef } from "react";
import PraanLogo from "../../../images/praan-logo.png";
import HiveRoom from "../../../images/Hive-Rooms.png";
import ExplodedView from "../../../images/hive-exploded-view.png";
import SystemIcon from "../../../images/icons8-system-task-80 1.png";
import GraphIcon from "../../../images/icons8-graph-80 1.png";
import WindIcon from "../../../images/icons8-wind-100 1.png";
import FilterIcon from "../../../images/FilterIcon.png";
import OuterArrowIcon from "../../../images/Vector 2.png";
import ArrowIcon from "../../../images/Ellipse 1.png";
import HivePackage from "../../../images/hive-packaging.png";
import HiveInstallation1 from "../../../images/79108.png";
import HiveInstallation2 from "../../../images/79109.png";
import Footer from "../../Home/Components/Footer/contact";

import "./hive.scss";
const HIVE = () => {
  return (
    <>
      <section className="hive-section">
        <div className="hive">
          <div className="hive-grid">
            <div className="hive-title">Hive</div>
            <div className="hive-heading">Fulltime Project</div>
            <div className="praan">
              <img src={PraanLogo} className="praan-logo" alt="" />
            </div>
            <div className="hive-inline">
              <div className="hive-description">
                Hive is an indoor air purifier built for B2B that I helped
                design and build while working at Praan inc.
              </div>
            </div>
            {/* <a href="#verticle-next">
              <div className="arrow">
                <img className="vector" src={OuterArrowIcon} alt="" srcset="" />
                <img className="ellipse" src={ArrowIcon} alt="" srcset="" />
              </div>
            </a> */}
          </div>
        </div>
        <div className="hive-rooms">
          <div className="hive-room-image">
            <img src={HiveRoom} className="hive-room-img" />
          </div>
          <div className="hive-grid">
            <div className="hive-subtitle">Scenario</div>
            <div className="hive-inline">
              <div className="hive-rooms-description">
                Hive was proposed to be built for indoor environments mainly
                offices, malls and schools etc.
              </div>
            </div>
          </div>
        </div>
        <div className="hive-ideation"></div>
        <div className="hive-technical-section">
          <div className="hive-technical"></div>
          <div className="hive-technical-grid">
            <div className="hive-subtitle">Technical Specifications</div>
            <div className="hive-inline">
              <div className="hive-technical-description">
                Removes PM 1.0, PM 2.5, PM 10, VOCs, and Microbes from the air
              </div>
            </div>
          </div>
        </div>
        <div className="hive-exploded-section">
          <div className="hive-exploded-grid">
            <div className="hive-subtitle">Exploded View</div>
            <div className="hive-inline">
              <div className="hive-exploded-description">
                The entire body of the HIVE device is made up of aluminium
              </div>
            </div>
          </div>
          <img src={ExplodedView} className="exploded-view-size" />
        </div>
        <div className="hive-environment-section">
          <div className="hive-environment-grid">
            <div className="hive-subtitle">
              Continuous <br />
              Environmental <br />
              Sensing
            </div>
            <div className="hive-inline">
              <div className="hive-environment-description">
                If you can't measure, you can't solve. It's time to truly
                understand the immediate world around you{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="hive-analytics-section">
          <div className="hive-analytics-subtitle">
            Actionable Analytics and Control
          </div>
          <div className="hive-analytics-grid">
            <div className="hive-analytics-grid-block">
              <div className="block-icon">
                <img src={SystemIcon} />
              </div>
              <div className="block-text">
                <b>Continuous environmental monitoring</b> with insights
                generation
              </div>
            </div>
            <div className="hive-analytics-grid-block">
              <div className="block-icon">
                <img src={WindIcon} />
              </div>
              <div className="block-text">
                <b>Pre-cleaning rooms</b> before people enter
              </div>
            </div>
            <div className="hive-analytics-grid-block">
              <div className="block-icon">
                <img src={FilterIcon} />
              </div>
              <div className="block-text">
                <b>Automated filter health</b> & maintenance monitoring
              </div>
            </div>
            <div className="hive-analytics-grid-block">
              <div className="block-icon">
                <img src={GraphIcon} />
              </div>
              <div className="block-text">
                <b>ESG Score Calculation</b> with reports
              </div>
            </div>
          </div>
        </div>
        <div className="hive-packaging-section">
          <div className="hive-packaging-grid">
            <div className="hive-packaging-subtitle">Packaging</div>
            <div className="hive-inline">
              <div className="hive-packaging-description">
                There were multiple Iterations made for the packaging of HIVE as
                we wanted to create a minimal, clean and sustainable packaging.
              </div>
              <div className="hive-packaging-subdescription">
                The materials considered for the packaging were :
              </div>
              <div className="hive-packaging-gridtitle">
                <div className="hive-packaging-subgridtitle">
                  <b>Green Cell Foam</b> <br />
                  highly sustainable packaging option. Green cell foam is made
                  out of corn, which makes it highly compostable.
                </div>
                <div className="hive-packaging-subgridtitle">
                  <b>Corn starch packaging</b> <br />
                  Its material is developed using polylactic acid (PLA), made by
                  fermenting sugars of the plant.
                </div>
                <div className="hive-packaging-subgridtitle">
                  <b>Molded Pulp</b> <br />
                  Its material is developed using polylactic acid (PLA), made by
                  fermenting sugars of the plant
                </div>
              </div>
            </div>
          </div>
          <div className="hive-packaging"></div>
        </div>
        <div className="hive-real-installation">
          <div className="installation-heading">
            <div className="installation-header">
              Hive installed in real offices
            </div>
            <div className="installation-subheading">
              Following are images of the HIVE devices installed in multiple
              offices in Mumbai, India
            </div>
          </div>
          <div className="image-grid-installation">
            <img src={HiveInstallation1} className="hive-installations-img" />
            <img src={HiveInstallation2} className="hive-installations-img" />
          </div>
        </div>
      </section>
      <Footer color={"#d9d9d9"} />
    </>
  );
};
export default HIVE;
